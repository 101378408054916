/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import {Link} from "react-router-dom";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
      <footer className={footerClasses}>
        <div className={classes.container}>
          <div className={classes.left}>
            <Link
                style={{textDecoration: "none"}}
                className={classes.block}
                to={"/"}
            >
              ©HV Tegels | HV tegels Antwerpen
            </Link>
          </div>

          <div className={classes.right}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={4}>
              <ListItem className={classes.inlineBlock}>
                <Link
                    to={"/privacybeleid"}
                    className={classes.block}
                >
                  Privacybeleid
                </Link>
              </ListItem>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <ListItem className={classes.inlineBlock}>
                <Link
                    to={"/gebruikersvoorwaarden"}
                    className={classes.block}
                >
                  Gebruiksvoorwaarden
                </Link>
              </ListItem>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <ListItem className={classes.inlineBlock}>
                <Link
                    to={"/verkoopsvoorwaarden"}
                    className={classes.block}
                >
                  Verkoopsvoorwaarden
                </Link>
              </ListItem>
            </GridItem>
          </GridContainer>
        </div>
      </div>
</footer>
);
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};