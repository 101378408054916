import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";

import "assets/scss/material-kit-react.scss?v=1.10.0";

const HomePage = lazy(() => import("./pages/home"));
const Contact = lazy(() => import("./pages/contact"));
import Header from "./components/Header/Header";
import HeaderLinks from "./components/Header/HeaderLinks";
import Footer from "./components/Footer/Footer";
const Privacybeleid = lazy(() => import("./pages/privacybeleid"));
const Gebruiksvoorwaarden = lazy(() => import("./pages/gebruiksvoorwaarden"));
const Verkoopsvoorwaarden = lazy(() => import("./pages/verkoopsvoorwaarden"));
import { AboveFooter } from "./components/Components/aboveFooter";
const TegelsPage = lazy(() => import("./pages/tegels"));
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RouteChangeTracker from "./components/Components/RouterTrack";

var hist = createBrowserHistory();

function toastOpen(hour = 9, day = 9) {
  let time = new Date();
  if (hour == 9 || day == 9) {
    hour = time.getHours();
    day = time.getDay();
  }
  let daymonth = time.getDate();
  let month = time.getMonth();

    if(month == 4 &&
      (daymonth == 21
      || daymonth == 22
      || daymonth == 23
      || daymonth == 24
      || daymonth == 25
      || daymonth == 28
      || daymonth == 29
      || daymonth == 30
      || daymonth == 31
      || daymonth == 31
      )){
    toast.dark("Wij zijn uitzonderlijk gesloten!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        });
    }
  else if (
    ((hour > 9 && hour < 18 && day !== 0) ||
      (hour > 11 && hour < 17 && day === 0)) &&
    day !== 3
  ) {
    toast.dark("Wij zijn nu geopend!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  } else if (day === 3) {
    toast.dark("Op woensdag zijn wij gesloten", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
}
toastOpen();

// toast.dark("Momenteel grote stockverkoop! OP=OP", {
//   position: "top-right",
//   autoClose: false,
//   hideProgressBar: true,
//   closeOnClick: true,
//   pauseOnHover: true,
//   draggable: true,
//   });

const TRACKING_ID = "UA-182323650-1";
ReactGA.initialize(TRACKING_ID);

ReactDOM.render(
  <div>
    <Router history={hist}>
      <RouteChangeTracker />
      <Suspense fallback={<div>Loading...</div>}>
        <Header
          brand={"HV Tegels"}
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white",
          }}
        />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Switch>
          <Route path="/contact" component={Contact} />
          <Route path="/privacybeleid" component={Privacybeleid} />
          <Route
            path="/gebruikersvoorwaarden"
            component={Gebruiksvoorwaarden}
          />
          <Route path="/verkoopsvoorwaarden" component={Verkoopsvoorwaarden} />
          <Route path="/tegels/:data" component={TegelsPage} />

          <Route path="/" component={HomePage} />
        </Switch>

        <AboveFooter />
        <Footer />
      </Suspense>
    </Router>
  </div>,
  document.getElementById("root")
);
